import "./style.scss";
import { Browser } from "@capacitor/browser";
import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { ToggleChangeEventDetail } from "@ionic/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { DuplicatedLicenseModal } from "@src/app/licenseManager/components/DuplicatedLicenseModal";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { CbhFeatureFlag, useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { LicenseStatuses } from "@src/appV2/Licenses/api/types";
import { useGetQualifications } from "@src/appV2/Qualifications/api/useGetQualifications";
import { Qualification, SpecialQualifications } from "@src/appV2/Qualifications/types";
import { GET_WORKER_PATH } from "@src/appV2/Worker/api/useGetWorker";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { SUPPORT_LINKS } from "@src/constants/DEFAULT_SUPPORT_LINKS";
import { USER_EVENTS } from "@src/constants/userEvents";
import { logApiFailureEvent } from "@src/lib/analytics";
import { states } from "@src/lib/utils";
import { useQueryClient } from "@tanstack/react-query";
import { informationCircleSharp } from "ionicons/icons";
import { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { addLicense } from "./api";
import { LICENSE_DETAILS_INIT } from "./constants";
import { AddLicenseFormProps, ICreateLicense, ILicenseDetails } from "./interfaces";
import { isLicenseDefaultActive } from "./utils";

const anyOption = { key: "Any", name: "Any" };

const AddLicenseForm: FC<AddLicenseFormProps> = ({
  licenseDetails,
  submitLicense,
  addingLicense,
  forOnboarding,
  disableForm,
}) => {
  const [license, setLicense] = useState(licenseDetails);
  const {
    data: qualificationsData,
    isSuccess: qualificationsAreLoaded,
    isLoading: qualificationsAreLoading,
  } = useGetQualifications({
    enabled: true,
  });

  const ldFlags = useCbhFlags();
  const location = useLocation();
  const screenParameters = location.state as {
    license: ILicenseDetails;
  };

  const licenseRequiredFields = ldFlags[CbhFeatureFlag.HCP_LICENSE_FIELDS_REQUIREMENTS];
  const licenseNumberRequirement = ldFlags[CbhFeatureFlag.HCP_LICENSE_NUMBER_REQUIREMENTS];
  const hcpLicenseDefaultStatus = useCbhFlag(CbhFeatureFlag.HCP_LICENSE_DEFAULT_STATUS, {
    defaultValue: {
      activeStatusRules: [],
    },
  });
  const nlcStatesObj = ldFlags[CbhFeatureFlag.NLC_STATES];

  const handleLicenseChange = (key: string, value: string | boolean) => {
    setLicense((prevLicenseDetails) => {
      function clearStateIfQualificationChanged(key: string, value: string, defaultValue: string) {
        if (key === "qualification") {
          return "";
        }

        if (key === "state") {
          return value;
        }

        return defaultValue;
      }

      return {
        ...prevLicenseDetails,
        [key]: value,
        state:
          typeof value === "boolean"
            ? prevLicenseDetails.state
            : clearStateIfQualificationChanged(key, value, prevLicenseDetails.state),
      };
    });
  };

  const shouldDisableLicenseNumberField = useMemo(() => {
    if (
      license.qualification === SpecialQualifications.MEDICAL_ASSISTANT ||
      license.qualification === SpecialQualifications.NON_CLINICAL ||
      (license.qualification === SpecialQualifications.CNA && license?.state === "Illinois")
    ) {
      handleLicenseChange("number", "");
      return true;
    }
    return false;
  }, [license.state, license.qualification]);

  const disableInputBasedOnFeatureFlag = useMemo((): {
    state: boolean;
    licenseNumber: boolean;
    multiState: boolean;
  } => {
    let state = false;
    let licenseNumber = false;
    let multiState = false;

    if (licenseRequiredFields[license.qualification]?.state === "hidden") {
      state = true;
      multiState = true;
      if (!screenParameters?.license?.state) {
        handleLicenseChange("state", anyOption.name);
      }
      if (!screenParameters?.license?.multiState) {
        handleLicenseChange("multiState", false);
      }
    }
    if (
      (licenseRequiredFields[license.qualification] &&
        licenseRequiredFields[license.qualification]["license-number"] === "hidden") ||
      shouldDisableLicenseNumberField
    ) {
      licenseNumber = true;
      if (!screenParameters?.license?.number) {
        handleLicenseChange("number", "");
      }
    }
    if (
      licenseRequiredFields[license.qualification] &&
      licenseRequiredFields[license.qualification]["multi-state"] === "hidden"
    ) {
      multiState = true;
      if (!screenParameters?.license?.multiState) {
        handleLicenseChange("multiState", false);
      }
    }
    if (
      licenseRequiredFields[license.qualification] &&
      licenseRequiredFields[license.qualification]["multi-state"] === "visible"
    ) {
      multiState = false;
    }
    return { state, licenseNumber, multiState };
  }, [
    licenseRequiredFields,
    license.qualification,
    shouldDisableLicenseNumberField,
    screenParameters?.license?.state,
    screenParameters?.license?.multiState,
    screenParameters?.license?.number,
  ]);

  const statesWithAnyOption = useMemo(() => {
    return [anyOption, ...states];
  }, []);

  let stateOptions = states;

  if (
    licenseRequiredFields?.[license.qualification]?.state === "hidden" ||
    licenseRequiredFields?.[license.qualification]?.["any-state"] === "allowed"
  ) {
    stateOptions = statesWithAnyOption;
  }

  const handleLicenseMultiStateChange = (event: CustomEvent<ToggleChangeEventDetail>) => {
    const { checked } = event.detail;
    setLicense((prevLicenseDetails) => ({
      ...prevLicenseDetails,
      multiState: checked,
    }));
  };

  const multiStateOptionProps = useMemo(() => {
    const multiStateProps = {
      disabled: false,
      reason: "",
    };
    if (disableInputBasedOnFeatureFlag.multiState) {
      multiStateProps.disabled = true;
      multiStateProps.reason = "This worker type is not eligible for NLC";
    } else {
      const nlcStatesArr = nlcStatesObj?.active_states ?? [];
      if (!nlcStatesArr.includes(license.state)) {
        handleLicenseChange("multiState", false);
        multiStateProps.disabled = true;
        if (license.state && license.state !== anyOption.key) {
          multiStateProps.reason = "This state is not part of the NLC";
        }
      }
    }
    return multiStateProps;
  }, [disableInputBasedOnFeatureFlag.multiState, license.state, nlcStatesObj]);

  useEffect(() => {
    if (stateOptions[0].name === anyOption.name && !license.state) {
      handleLicenseChange("state", anyOption.key);
    }
  }, [stateOptions, license.state]);

  useEffect(() => {
    if (!screenParameters) {
      return;
    }
    const { license: prefilledLicense } = screenParameters;
    if (!prefilledLicense) {
      return;
    }
    setLicense((previousValue) => ({
      ...previousValue,
      qualification: prefilledLicense.qualification,
      multiState: prefilledLicense.multiState,
      state: prefilledLicense.state,
      number: prefilledLicense.number,
    }));
  }, [screenParameters]);

  const enableSubmitButton = useMemo(() => {
    /**
     * If a worker comes back to the onboarding process after a while, they might get stuck on the "Add License" page.
     * This can happen because in onboarding process we deactivate the license form when a worker has already added a license,
     * and the rules for validating licenses may have changed during the interim, making their previously added license invalid and blocking the "Next" button.
     * To address this we "enable the submit button without validation if the form is for onboarding and disabled."
     **/
    if (forOnboarding && disableForm) {
      return true;
    }

    const { qualification, state, number } = license;
    if (qualification?.length === 0) {
      return false;
    }

    const isLicenseNumberRequired = ((licenseNumberRequirement ?? {})[state] ?? []).includes(
      qualification
    );

    if (number?.length === 0 && isLicenseNumberRequired) {
      return false;
    }

    return true;
  }, [disableForm, forOnboarding, license, licenseNumberRequirement]);

  const submitButtonText = useMemo(() => {
    if (forOnboarding) {
      return "Next";
    }

    if (!disableInputBasedOnFeatureFlag.licenseNumber) {
      return "Submit License";
    }

    return "Submit Qualification";
  }, [forOnboarding, disableInputBasedOnFeatureFlag.licenseNumber]);

  const shouldShowLicenseFields = useMemo(() => {
    return !disableInputBasedOnFeatureFlag.licenseNumber || shouldDisableLicenseNumberField;
  }, [disableInputBasedOnFeatureFlag.licenseNumber, shouldDisableLicenseNumberField]);

  const headerTextContent = useMemo(() => {
    if (forOnboarding) {
      return "Add the primary type of shift now; you can add more once you've completed signup.";
    }
    if (
      isDefined(license) &&
      isLicenseDefaultActive({
        defaultActiveLicenseRules: hcpLicenseDefaultStatus.activeStatusRules ?? [],
        license,
      })
    ) {
      return "Enter your new license/qualification info here. It will become Active immediately after submitting!";
    }

    return "Enter your new license/qualification info here. We will validate with the state license authority and notify you when we've accepted your license/qualification.";
  }, [forOnboarding, hcpLicenseDefaultStatus?.activeStatusRules, license]);

  return (
    <IonContent className="add-license">
      <div className="add-license-wrapper">
        <div className="add-license-header" data-testid="add-licenses-header">
          {forOnboarding && <h4>Which types of shifts do you want to work?</h4>}
          <p>{headerTextContent}</p>
        </div>
        <IonCard className="add-license-card" data-testid="licenses-card">
          <IonCardContent className="add-license-card-content">
            <div className="form-control">
              <Autocomplete<Qualification>
                aria-label="Choose Qualification Type"
                options={
                  qualificationsAreLoaded
                    ? qualificationsData.data.filter(
                        (qualification) =>
                          qualification.attributes.name !== SpecialQualifications.NURSE
                      )
                    : []
                }
                getOptionLabel={(option) => option.attributes.displayLabel}
                disabled={disableForm ?? qualificationsAreLoading}
                value={
                  qualificationsAreLoaded
                    ? qualificationsData?.data.find(
                        (qualification) => qualification.attributes.name === license?.qualification
                      )
                    : null
                }
                filterOptions={createFilterOptions<Qualification>({
                  stringify: (option) => {
                    return `${option.attributes.displayLabel} (${option.attributes.name})`;
                  },
                })}
                onChange={(_event, newQualification) => {
                  if (!isDefined(newQualification)) {
                    return;
                  }

                  handleLicenseChange("qualification", newQualification.attributes.name);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Qualification" />
                )}
              />
            </div>
            {!license.qualification ||
              (shouldShowLicenseFields && (
                <>
                  <div style={{ marginBottom: "15px" }}>
                    <IonRow className="ion-align-items-center no-padding license-state-row">
                      <IonCol size="8" className="form-control">
                        <Autocomplete
                          aria-label="Choose License State"
                          disabled={disableForm || disableInputBasedOnFeatureFlag.state}
                          value={license?.state && license?.state !== "" ? license?.state : null}
                          onChange={(_event, value) => {
                            if (!isDefined(value)) {
                              return;
                            }

                            handleLicenseChange("state", value);
                          }}
                          options={stateOptions.map((state) => state.name)}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" label="STATE" />
                          )}
                        />
                      </IonCol>

                      {!multiStateOptionProps.disabled && (
                        <IonCol size="4" className="form-control license-multi-state-col">
                          <IonLabel
                            class="ion-text-wrap"
                            color="#4F4F4F"
                            className="license-multi-state-label"
                          >
                            <p>MULTI-STATE</p>
                          </IonLabel>
                          <IonToggle
                            data-testid="add-license-multi-state-toggle"
                            aria-label="Select a license state"
                            className="license-multi-state-toggle"
                            color="primary"
                            checked={license.multiState}
                            onIonChange={handleLicenseMultiStateChange}
                            disabled={disableForm}
                          />
                        </IonCol>
                      )}
                    </IonRow>
                    {multiStateOptionProps.reason && (
                      <IonButton
                        data-testid="add-license-multi-state-non-availability-msg"
                        style={{ marginTop: 0 }}
                        fill="clear"
                        size="small"
                        onClick={async () => {
                          await Browser.open({ url: SUPPORT_LINKS.MULTI_STATE_LICENSES });
                        }}
                      >
                        {multiStateOptionProps.reason}
                        <IonIcon icon={informationCircleSharp} mode="ios" color="primary" />
                      </IonButton>
                    )}
                  </div>
                  {!disableInputBasedOnFeatureFlag.licenseNumber && (
                    <div className="form-control">
                      <IonLabel class="ion-text-wrap" color="#4F4F4F">
                        <p>LICENSE NUMBER</p>
                      </IonLabel>
                      <IonInput
                        aria-label="Add License Number"
                        data-testid="add-license-number"
                        name="number"
                        className="form-input"
                        type="text"
                        placeholder="123456"
                        value={license.number}
                        onIonChange={(event) => handleLicenseChange("number", event.detail.value!)}
                        required
                        disabled={disableForm}
                      />
                    </div>
                  )}
                </>
              ))}
          </IonCardContent>
        </IonCard>
        <IonButton
          expand="block"
          className="submit-license-button"
          onClick={() => {
            submitLicense(license);
          }}
          disabled={!enableSubmitButton}
        >
          {addingLicense && <IonSpinner></IonSpinner>}
          {submitButtonText}
        </IonButton>
      </div>
    </IonContent>
  );
};

const AddLicense: FC<{}> = () => {
  const history = useHistory();
  const worker = useDefinedWorker();
  const { userId, email: workerEmail } = worker;

  const modalState = useModalState();

  const [addingLicense, setAddingLicense] = useState<boolean>(false);
  const [duplicatedLicenseStatus, setDuplicatedLicenseStatus] = useState<LicenseStatuses>();

  const { showErrorToast } = useToast();

  const queryClient = useQueryClient();

  const submitLicense = (license: ICreateLicense) => {
    (async () => {
      try {
        setAddingLicense(true);
        const response = await addLicense(license, String(userId));

        if (response.duplicate) {
          modalState.openModal();
          setDuplicatedLicenseStatus(response.status);
          return;
        }

        if (response.status === LicenseStatuses.ACTIVE) {
          // refetch worker to get updated worker qualification on successfull add
          await queryClient.invalidateQueries([GET_WORKER_PATH]);

          logEvent(USER_EVENTS.WORKER_LICENSE_ACTIVATED, {
            email: workerEmail,
          });
        }
        logEvent(USER_EVENTS.LICENSE_SUBMITTED, {
          qualification: license.qualification,
          state: license.state,
        });
        history.replace(TabRouterPath.LICENSE_MANAGER, { forceReload: true });
      } catch (error) {
        logApiFailureEvent(error);
        showErrorToast("Error while adding license");
      } finally {
        setAddingLicense(false);
      }
    })();
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account/licenses" mode="ios" />
          </IonButtons>
          <IonTitle>Add License / Qualification</IonTitle>
        </IonToolbar>
      </IonHeader>
      <AddLicenseForm
        licenseDetails={LICENSE_DETAILS_INIT}
        submitLicense={submitLicense}
        addingLicense={addingLicense}
        forOnboarding={false}
        disableForm={false}
      />

      <DuplicatedLicenseModal
        modalState={modalState}
        existingLicenseStatus={duplicatedLicenseStatus}
      />
    </IonPage>
  );
};

export { AddLicense, AddLicenseForm };
